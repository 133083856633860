import React, { useState } from 'react';
import { graphql } from 'gatsby';
import './index.scss';
import PreferedHost from '../components/PreferedHost';
import DevelopModels from '../components/DevelopModels';
import CreateAIModules from '../components/CreateAIModules';
import BuildKnowledge from '../components/BuildKnowledge';
import OrchestratePipelines from '../components/OrchestratePipelines';
import { mapPosts } from '../misc/dataParsers';
import Layout from '../layout';
import Aquarium from '../components/Aquarium';
import HomeBlog from '../components/HomeBlog';
import SEO from '../components/SEO';
import DistributeMLWorkload from '../components/DistributeMLWorkload';
import ManageYourData from '../components/ManageYourData';
import UsersAndOrganizations from '../components/UsersAndOrganizations';
import HomeTopBanner from '../components/HomeTopBanner/HomeTopBanner';
import SuccessStoriesAndStatistics from '../components/SuccessStories&Statistics/SuccessStories&Statistics';

const HomePage = props => {
  const {
    data: {
      blog,
      dataYaml: { homepage: data },
    },
    location,
  } = props;

  const [subscriptionEmail, setSubscriptionEmail] = useState('');
  const subscriptionSuccess = false;

  // console.log(data);

  const {
    topBanner,
    iconsBanner,
    subscriptionBanner,
  } = data;

  return (
    <Layout pathname={location.pathname} withWedges={false} className="home-page">
      <SEO />
      <Aquarium className="home-page-top-banner bg-dark">
        <HomeTopBanner />
      </Aquarium>
      <SuccessStoriesAndStatistics />
      <div className="home-page-collaboration-section">
        <div className="home-page-collaboration-section-content">
          <p className="title">
            <span style={{ color: 'var(--info)' }}>
              Collaboration,
            </span>
            {' '}
            the key for more and better Data Science
          </p>
          <p className="desc">
            MLReef enables
            {' '}
            <span style={{ color: 'var(--info)', fontWeight: 'bold' }}>
              Domain Experts
            </span>
            {' '}
            and
            {' '}
            <span style={{ color: 'var(--info)', fontWeight: 'bold' }}>
              Data Scientists
            </span>
            {' '}
            to securely collaborate
            via a hybrid of pro-code & no-code development approach
          </p>
        </div>
      </div>
      <div className="home-page-statistics">
        <div className="home-page-statistics-container">
          <div className="home-page-statistics-container-item">
            <p className="title">Increased productivity</p>
            <p className="description">
              75% increase in productivity due to distributed workloads.
              This enables teams to complete more ML projects faster.
            </p>
          </div>
          <div className="home-page-statistics-container-item">
            <h3 className="title">Better collaboration</h3>
            <p className="description">
              Domain Experts and Data Scientists collaborate on the same
              platform reducing 100% of unnecessary communication ping-pong.
            </p>
          </div>
          <div className="home-page-statistics-container-item">
            <h3 className="title">Secured Continuity</h3>
            <p className="description">
              MLReef works on your premises and uniquely enables 100% reproducibility
              and continuity. Rebuild all work at any time.
            </p>
          </div>
        </div>
      </div>

      <section id="why-users-love-mlreef">
        <div className="home-page-icons-banner bg-dark">
          <div className="home-page-icons-banner-container">
            <div className="home-page-icons-banner-title">
              <h5 className="t-white">
                Why users love MLReef
              </h5>
            </div>
            <div className="home-page-icons-banner-content">
              {iconsBanner.items.map((item) => (
                <div
                  key={`icons-banner-${item.title}-${item.subtitle}`}
                  className="home-page-icons-banner-content-item"
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="home-page-icons-banner-content-item-icon"
                  />
                  <div className="home-page-icons-banner-content-item-content">
                    <div
                      className="home-page-icons-banner-content-item-content-title"
                    >
                      {item.title}
                    </div>
                    <div
                      style={{ color: item.color }}
                      className="home-page-icons-banner-content-item-content-subtitle"
                    >
                      {item.subtitle}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <CreateAIModules />

      <DistributeMLWorkload />

      <ManageYourData />

      <DevelopModels />

      <OrchestratePipelines />

      <BuildKnowledge />

      <PreferedHost />

      <UsersAndOrganizations />

      {blog && blog.nodes.length && <HomeBlog posts={mapPosts(blog.nodes)} limit={3} />}

      {subscriptionBanner && (
        <section className="home-page-section subscription">
          <div className="subscription-title">{subscriptionBanner.title}</div>
          {subscriptionBanner.text.split('\n').map(line => (
            <p
              key={`subscription-text-${line}`}
              className="subscription-text-line"
            >
              {line}
            </p>
          ))}
          <form
            action={subscriptionBanner.action}
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="subscription-form d-flex"
            target="_blank"
            noValidate
          >
            <div className="subscription-form-group btn-group mx-auto t-center">
              <input
                type="email"
                name="EMAIL"
                className="subscription-form-control border-rounded-left"
                id="mce-EMAIL"
                placeholder={subscriptionBanner.placeholder}
                value={subscriptionEmail}
                onChange={e => setSubscriptionEmail(e.target.value)}
                required
              />
              <button type="submit" className="btn btn-primary px-3">
                {subscriptionBanner.buttonLabel}
              </button>
            </div>

          </form>
          <div className={`subscription-success ${subscriptionSuccess ? 'show' : ''}`}>
            {subscriptionBanner.thankMessage}
          </div>
          <p className="banner-wide-text mt-1">
            <strong>
              And join our channels & community
            </strong>
          </p>
          <div className="banner-wide-actions">
            <a target="_black" rel="noopener noreferrer" href="https://twitter.com/MLReef" className="banner-wide-actions-icon">
              <img className="" alt="twitter icon" src="/svg/icons/twitter_active.svg" />
            </a>
            <a target="_black" rel="noopener noreferrer" href="https://www.linkedin.com/company/mlreef/" className="banner-wide-actions-icon">
              <img className="" alt="linkedin icon" src="/svg/icons/linkedin_active.svg" />
            </a>
            <a target="_black" rel="noopener noreferrer" href="https://discord.gg/draNC396CM" className="banner-wide-actions-icon">
              <img className="" alt="discord icon" src="/svg/icons/discord_active.svg" />
            </a>
          </div>

        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query FetchHomePageData {
    blog: allFile(
      filter: {sourceInstanceName: {eq: "blog"}},
      sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}
    ) {
      nodes {
        post: childMarkdownRemark {
          frontmatter {
            author
            image
            title
          }
          excerpt
        }
      }
    }

    dataYaml {
      homepage {
        topBanner {
          footerText1
          footerText2
          text
          title
          words {
            content
            color
          }
        }
        iconsBanner {
          items {
            title
            subtitle
            icon
            color
          }
        }
        sponsors {
          logo
          title
          link
        }
        contactBanner {
          title
          text
          buttonLabel
          href
        }
        examples {
          title
          text
          desktopImage
          images
        }
        accountFeatures {
          title
          image
          text
        }
        subscriptionBanner {
          title
          text
          buttonLabel
          placeholder
          action
          thankMessage
        }
      }
    }
  }
 `;

export default HomePage;
