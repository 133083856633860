import React from 'react';
import './PreferedHost.scss';
import ubuntuIc from '../../../static/images/nautilusImages/image.png';
import dockerIc from '../../../static/images/nautilusImages/image_1.png';
import mlreefIc from '../../../static/images/nautilusImages/image_2.png';

const PreferedHost = () => (
  <div className="prefered-host">
    <h2 className="title"><span style={{ color: '#2db391' }}>Use</span> your preferred host</h2>
    <div className="prefered-host-content">
      <div className="prefered-host-content-left">
        <div className="mlreef-icon">
          <img src={mlreefIc} alt="" height="30" width="110" />
          <p className="description m-1">host on mlreef.com</p>
        </div>
        <div className="self-host">
          <p className="description">self-host on any premise or cloud infra</p>
          <div className="icons">
            <img src={ubuntuIc} alt="" height="60" width="100" />
            <img src={dockerIc} alt="" height="30" width="110" />
          </div>
        </div>
      </div>
      <div className="prefered-host-content-right">
        <p className="indication">NAUTILUS</p>
        <h3 className="title">
          <span style={{ color: '#2db391' }}>Host</span> MLReef on any infrastructure - on the cloud or on-premises
        </h3>
        <p className="description">
          MLReef comes as a docker image to be installed wherever you need it.
          Secure your data policies with full flexibility
        </p>
      </div>
    </div>
  </div>
);

export default PreferedHost;
