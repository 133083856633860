import React from 'react';
import Checkbox from '../Checkbox';
import './OrchestratePipelines.scss';
import orchestratePNG from '../../../static/images/homepage/orchestrate.png';
import ProjectCard from '../ProjectCard';
import { useQuery } from 'react-query';
import { processApiCall } from '../../common/helpers';
import orchestrate from '../../../static/images/homepage/orchestrate.png'

const OrchestratePipelines = () => {

  return (
    <>
      <h2 className="orchestrate-pipelines-title"><span style={{ color: '#ebba45' }}>Orchestrate</span> and automate pipelines</h2>
      <div className="orchestrate-pipelines">
        <div className="orchestrate-pipelines-content">
          <div className="orchestrate-pipelines-content-left">
            <img src={orchestrate} alt="" width="95%" className="orchestrate-pipelines-content-left-image" />
          </div>
          <div className="orchestrate-pipelines-content-right">
            <p className="indication">Pipeline Graph - ROADMAP</p>
            <h3 className="title">
              <span style={{ color: '#ebba45' }}>Create</span> interconnected pipeline graphs
            </h3>
            <p className="description">
              Build complex pipelines via drag and drop or via jaml configuration. Set automation and sheduling
              rules and set best-practice CI/CD workflows. 

            </p>
            <div className="checkmarks">
              <Checkbox label="Create complex pipeline flows" />
              <Checkbox label="Orchestrate resources" />
              <Checkbox label="Set custom automation and sheduling rules" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrchestratePipelines;
