import React from 'react';
import Checkbox from '../Checkbox';
import './CreateAIModules.scss';
import modulesGif from '../../../static/gifs/modulesSection.gif';
import ProjectCard from '../ProjectCard';
import { useQuery } from 'react-query';
import { processApiCall } from '../../common/helpers';

const CreateAIModules = () => {
  /*   
  const { data: algorithms, isFetching: isLoadingAlgorithms, isError: isErrorAlgorithms } = useQuery('algorithms', async () => processApiCall([
    { name: "searchable_type", value: 'ALGORITHM' },
    { name: "gitlab_namespace", value: "erikaml" },
    { name: "name", value: "XGboost" },
  ]));

  const { data: operations, isFetching: isLoadingOperations, isError: isErrorOperations } = useQuery('operations', async () => processApiCall([
    { name: "searchable_type", value: 'OPERATION' },
    { name: "name", value: "Add noise" },
    { name: "page", value: "0" },
    { name: "size", value: "1" },
  ]));

  const { data: visualizations, isFetching: isLoadingVisualizations, isError: isErrorVisualizations } = useQuery('visualizations', async () => processApiCall([
    { name: "searchable_type", value: 'VISUALIZATION' },
    { name: "name", value: "WordCloud" },
    { name: "page", value: "0" },
    { name: "size", value: "1" },
  ]));

  
  
  
  const isLoading = isLoadingAlgorithms && isLoadingOperations && isLoadingVisualizations;
  const isError = isErrorAlgorithms && isErrorOperations && isErrorVisualizations;
const generateCardsSection = () => {
    if (isLoading) {
      return (
        <p>
          loading...
        </p>
      )
    } else if (!isLoading && !isError) {
      return (
        <>
          {algorithms && <ProjectCard 
            cardType="small" 
            projectType="model" 
            name={algorithms[0]?.name} 
            namespace={algorithms[0]?.gitlab_namespace} 
            href={`https://mlreef.com/${algorithms[0]?.gitlab_namespace}/${algorithms[0]?.slug}`}
          />}
          {operations && <ProjectCard 
            cardType="small" 
            projectType="processor" 
            name={operations[0]?.name} 
            namespace={operations[0]?.gitlab_namespace} 
            href={`https://mlreef.com/${operations[0]?.gitlab_namespace}/${operations[0]?.slug}`}
          />}
          {visualizations && <ProjectCard 
            cardType="small" 
            projectType="visualization" 
            name={visualizations[0]?.name} 
            namespace={visualizations[0]?.gitlab_namespace} 
            href={`https://mlreef.com/${visualizations[0]?.gitlab_namespace}/${visualizations[0]?.slug}`}
          />}
        </>
      )
    } else if (isError){
      return (
        <p>
          Error loading cards
        </p>
      )
    }
  } */

  return (
    <>
      <h2 className="create-ai-modules-title"><span style={{ color: '#32afc3' }}>Create</span> custom AI Modules</h2>
      <div className="create-ai-modules">
        <div className="create-ai-modules-content">
          <div className="create-ai-modules-content-left">
            <img src={modulesGif} alt="" width="95%" className="create-ai-modules-content-left-image" />
          </div>
          <div className="create-ai-modules-content-right">
            <p className="indication">AI MODULES</p>
            <h3 className="title">
              <span style={{ color: '#32afc3' }}>Create</span> easy operable AI Modules from Git repositories
            </h3>
            <p className="description">
              You can use already well known and established git 
              repositories to create explorable, interoperable and versioned AI Modules.
            </p>
            <div className="checkmarks">
              <Checkbox label="Immutable, reusable and sharable" />
              <Checkbox label="CI/CD based workflows" />
              <Checkbox label="Knwodledge base of working code" />
            </div>
          </div>
        </div>
 {/*        <div className="create-ai-modules-cards">
        {generateCardsSection()}
        </div>
        <div className="create-ai-modules-explore-btn-container">
          <a href="https://mlreef.com/explore/public/algorithm">
            Explore all public AI Modules
          </a>
        </div> */}
      </div>
    </>
  );
};

export default CreateAIModules;
