import React from 'react';
import { useQuery } from 'react-query';
import { processApiCall } from '../../common/helpers';
import ProjectCard from '../ProjectCard';
import Checkbox from '../Checkbox';
import './DistributeMLWorkload.scss';
import experimentsImg from '../../../static/images/Experiments_Pipeline.png'

const DistributeMLWorkload = () => {
/*   const { 
    data: demandForecasting, 
    isFetching: isFetchingProj1, 
    isError: isErrorProj1 
  } = useQuery(
    'data-projects-1', 
    async () => processApiCall([
      { name: 'name', value: 'Demand forecasting' },
      { name: 'namespace', value: 'akane' },
      { name: 'page', value: '0' },
      { name: 'size', value: '1' },
    ])
  );

  const { 
    data: cropType, 
    isFetching: isFetchingProj2, 
    isError: isErrorProj2 
  } = useQuery(
    'data-projects-2', async () => processApiCall([
      { name: 'name', value: 'Crop type classification' },
      { name: 'namespace', value: 'erikaml' },
      { name: 'page', value: '0' },
      { name: 'size', value: '1' },
    ])
  );

  const { 
    data: fraudDetection, 
    isFetching: isFetchingProj3, 
    isError: isErrorProj3 
  } = useQuery(
    'data-projects-3', 
    async () => processApiCall([
      { name: 'name', value: 'Fraud detection in electricity' },
      { name: 'namespace', value: 'erikaml' },
      { name: 'page', value: '0' },
      { name: 'size', value: '1' },
    ])
  );

  const isError = isErrorProj1 && isErrorProj2 && isErrorProj3;

  const isFetching = isFetchingProj1 && isFetchingProj2 && isFetchingProj3; */

/*   const generateCardsSection = () => {
    if (isFetching) {
      return (
        <p>
          loading...
        </p>
      )
    } else if (!isFetching && !isError) {
      return (
        <>
          {demandForecasting && <ProjectCard
            name={demandForecasting[0]?.name}
            namespace={demandForecasting[0]?.gitlab_namespace}
            cardType="small"
            image={demandForecasting[0].cover_url}
            href={`https://mlreef.com/${demandForecasting[0]?.gitlab_namespace}/${demandForecasting[0]?.slug}`}
          />}
          {cropType && <ProjectCard
            name={cropType[0]?.name}
            namespace={cropType[0]?.gitlab_namespace}
            cardType="small"
            image={cropType[0].cover_url}
            href={`https://mlreef.com/${cropType[0]?.gitlab_namespace}/${cropType[0]?.slug}`}
          />}
          {fraudDetection && <ProjectCard
            name={fraudDetection[0]?.name}
            namespace={fraudDetection[0]?.gitlab_namespace}
            cardType="small"
            image={fraudDetection[0].cover_url}
            href={`https://mlreef.com/${fraudDetection[0]?.gitlab_namespace}/${fraudDetection[0]?.slug}`}
          />}
        </>
      )
    } else if (isError){
      return (
        <p>
          Error loading cards
        </p>
      )
    }
  } */

  return (
    <>
      <h2 className="distribute-ml-workload-title"><span style={{ color: '#91bd44' }}>Distribute</span> ML workload</h2>
      <div className="distribute-ml-workload">
        <div className="distribute-ml-workload-content">
          <div className="distribute-ml-workload-content-left">
            <p className="indication">PIPELINES</p>
            <h3 className="title">
              <span style={{ color: '#91bd44' }}>Empower</span> everyone to participate in the ML value chain with low/no code
            </h3>
            <p className="description">
              AI Modules created by your data scientists become drag and drop elements.
              These are adjustable by parameters, versioned, interoperable and explorable
              within your entire organization.
            </p>
            <div className="checkmarks">
              <Checkbox label="Fast iteration of data, code and pipelines" />
              <Checkbox label="Easy to use and still highly flexible" />
              <Checkbox label="Concurrent computing pipelines" />
            </div>
          </div>
          <div className="distribute-ml-workload-content-right">
            <img src={experimentsImg} alt="" width="95%" className="experiments-icon" />
          </div>
        </div>
{/*         <div className="distribute-ml-workload-cards">
          {generateCardsSection()}
        </div> 
        */}
{/*         <div className="distribute-ml-workload-explore-ml-projects">
          <a href="https://mlreef.com/explore">
            Explore all public ML Projects
          </a>
        </div> */}
      </div>
    </>
  );
};

export default DistributeMLWorkload;
