import React from 'react';
import cx from 'classnames';
import './VideoModal.scss';
import IntroMlreefVid from '../../../static/videos/Introducing-MLReef.mp4';

const VideoModal = (props) => {
  const {
    show,
    setShow,
  } = props;

  if (show) {
    return (
      <div className={cx('modal modal-default dark-cover', { show })} style={{ zIndex: 1000 }}>
        <div className="modal-cover">
          <div className="video-modal">
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem 20% 0 0 ' }}>
              <button
                className="btn"
                style={{ backgroundColor: 'transparent', border: '1px solid white', color: 'white' }}
                type="button"
                onClick={() => setShow(false)}
              >
                close
              </button>
            </div>
            <div className="home-page-video d-flex">
              <video controls>
                <source src={IntroMlreefVid} type="video/ogg" />
              </video>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>Watch what MLReef can do for you!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default VideoModal;
