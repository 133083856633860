import React from 'react';
import './BuildKnowledge.scss';
import Checkbox from '../Checkbox';
import knowledgeImg from '../../../static/images/homepage/knowledge.png';

const BuildKnowledge = () => (
  <div className="build-knowledge">
    <h2 className="title"><span style={{ color: '#32afc3' }}>Access</span> a pre-built ML knowledge base</h2>
    <div className="build-knowledge-content">
      <div className="build-knowledge-content-left">
        <p className="indication">KNOWLEDGEBASE</p>
        <h3 className="title">
        <span style={{ color: '#32afc3' }}>Access</span> ready to use & open-source Datasets, Data Operators and Models
        </h3>
        <p className="description">
          Start producing value by accessing a pre-built ML knowledgebase. Access hundreds of 
          medical imaging datasets and ready to use AI Modules for image processing and Machine Learning models. 
        </p>
        <div className="checkmarks">
          <Checkbox label="Direct access to open-source medical datasets" />
          <Checkbox label="Instant use of open-source AI Modules" />
          <Checkbox label="Produce value within minutes" />
        </div>
      </div>
      <div className="build-knowledge-content-right">
        <img src={knowledgeImg} alt="" width="100%" height="auto" />
      </div>
    </div>
  </div>
);

export default BuildKnowledge;
