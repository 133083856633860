import React, { useState } from 'react';
import { Link } from 'gatsby';
import './HomeTopBanner.scss';
import military from '../../../static/images/homepage/military.jpg';
import healthcare from '../../../static/images/homepage/healthcare.jpg';
import VideoModal from './VideoModal';

const HomeTopBanner = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="home-page-top-banner-container">
      <VideoModal show={show} setShow={setShow} />
      <div className="home-page-top-banner-container-left">
        <p className="text-1">
          Built for collaboration-sensitive ML use cases
        </p>
        <h1 className="text-2">
          The Secure ML Collaboration Platform for Regulated Industries
        </h1>
        <div className="home-page-top-banner-container-left-buttons">
          <Link
            to="/book-a-demo"
            className="btn book-a-demo"
          >
            Reach out
          </Link>
          <button
            type="button"
            className="btn video-button"
            onClick={() => {
              setShow(!show);
            }}
          >
            What is MLReef?
          </button>
        </div>
      </div>
      <div className="home-page-top-banner-container-right">
        <img src={healthcare} alt="" className="healthcare" />
        <img src={military} alt="" className="military" />
      </div>
    </div>
  );
};

export default HomeTopBanner;
