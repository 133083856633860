import React from 'react';
import Avatar from '../Avatar';
import './UsersAndOrganizations.scss';
import amazonLogo from '../../../static/images/organizations/Amazon-logo.jpg';
import esaLogo from '../../../static/images/organizations/ESA-Logo.jpg';
import saabLogo from '../../../static/images/organizations/Saab-Logo.jpg';
import slgLogo from '../../../static/images/organizations/SLG-Logo.jpg';

const UsersAndOrganizations = () => (
  <div className="users-and-organizations">
    <div className="title-container">
      <h3 className="title">Trusted by thousands of users and organizations</h3>
    </div>
    <div className="users-and-organizations-companies-logos">
      <div className="item" style={{ display: 'flex', alignItems: 'center' }}>
        <a target="_blank" rel="noreferrer" href="http://amazon.com">
          <img height="40" width="120" src={amazonLogo} alt="" />
        </a>
      </div>
      <div className="item">
        <a target="_blank" rel="noreferrer" href="http://esa.int">
          <img height="70" width="80" src={esaLogo} alt="" />
        </a>
      </div>
      <div className="item">
        <a target="_blank" rel="noreferrer" href="http://saab.com">
          <img height="70" width="120" src={saabLogo} alt="" />
        </a>
      </div>
      <div className="item">
        <a target="_blank" rel="noreferrer" href="https://www.slg.co.at">
          <img height="70" width="60" src={slgLogo} alt="" />
        </a>
      </div>
    </div>
    <div className="users-and-organizations-icons">
      {['AA.jpg', 'C.jpg', 'diego.jpg', 'DW.jpg', 'ET.jpg', 'FH.jpg',
        'HC.jpg', 'L.jpg', 'M.jpg', 'Manfred.jpg', 'RK.jpg',
        'ST.jpg', 'V.jpg',
      ]
        .map((imgName, ind) => (
          <div style={{ display: 'flex', alignItems: `flex-${ind % 2 === 0 ? 'start' : 'end'}`, padding: '0 10px' }}>
            <Avatar img={`/images/avatars/${imgName}`} />
          </div>
        ))}
    </div>
    <div className="footer-text-container">
      <h3 className="footer-text">Unlock your Machine Learning potential!</h3>
    </div>
    {<div className="d-flex register-button-container">
      <a className="register-button" href="https://www.mlreef.com/book-a-demo">
        Request a demo!
      </a>
    </div>}
  </div>
);

export default UsersAndOrganizations;
