import React from 'react';
import MParagraph from '@externals/components/MParagraph';
import './ProjectCard.scss';

const projectTypes = {
  data: {
    label: 'ML Project',
    color: '#91bd44',
  },
  model: {
    label: 'Model',
    color: 'rgb(233, 148, 68)',
  },
  processor: {
    label: 'Data Processor',
    color: 'rgb(210, 81, 157)',
  },
  visualization: {
    label: 'Data Visualization',
    color: '#735da8',
  },
};

const ProjectCard = props => {
  const {
    image,
    name,
    namespace,
    href,
    description,
    projectType,
    cardType,
  } = props;

  return (
    <div className="ml-project-card-container">
      <a
        href={href}
        className="project-card-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={`ml-project-card ${cardType}`}
          style={{
            borderColor: projectTypes[projectType].color,
            boxShadow: `1px 1px 3px 1px ${projectTypes[projectType].color}`,
          }}
        >
          <div
            className="ml-project-card-tag"
            style={{ backgroundColor: projectTypes[projectType].color }}
          >
            {projectTypes[projectType].label}
          </div>
          {image && (
            <div className="ml-project-card-image-container">
              <img className="ml-project-card-image" src={image} alt={name} width="200" height="200" />
            </div>
          )}
          <div className="ml-project-card-content">
            <div className={`ml-project-card-content-title ${cardType}`} style={image ? { marginTop: 0 } : {}}>
              {name}
            </div>
            <div className={`ml-project-card-content-namespace ${cardType}`}>
              {namespace}
            </div>
            {description && (
              <div className="ml-project-card-content-description">
                <MParagraph className="" text={description} />
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

ProjectCard.defaultProps = {
  projectType: 'data',
};

export default ProjectCard;
