import React from 'react';
import Checkbox from '../Checkbox';
import './ManageYourData.scss';
import dataManagementSection from '../../../static/images/dataManagement/MLReef_pipelines.png';

const ManageYourData = () => (
  <div className="manage-your-data">
    <h2 className="title"> <span style={{ color: '#d2519d' }}>Manage</span> your data, collaboratively</h2>
    <div className="manage-your-data-content">
      <div className="manage-your-data-content-left">
        <img src={dataManagementSection} alt="" width="100%" height="auto" />
      </div>
      <div className="manage-your-data-content-right">
        <p className="indication">DATA MANAGEMENT</p>
        <h3 className="title">
          Make data processing a collaborative and connected work
        </h3>
        <p className="description">
          Data handling often requires expert knowledge that a single data scientist often lacks.
          MLReef enables your field experts to relieve your data processing task,
          reducing complexities and increasing data quality for model training.
        </p>
        <div className="checkmarks">
          <Checkbox label="Use integrations to your data warehouse" />
          <Checkbox label="Launch data processing & visualizations" />
          <Checkbox label="Store versions of dataset" />
        </div>
      </div>
    </div>
  </div>
);

export default ManageYourData;
