import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './SuccessStories&Statistics.scss';
import internalAIML from '../../../static/images/successStories/internalAIML.png';
import knowledgebase from '../../../static/images/successStories/knowledgebase.png';
import graph from '../../../static/images/successStories/graph.png';
import onPremise from '../../../static/images/successStories/on_premise.png';
import caduceus from '../../../static/images/successStories/caduceus.png';
import saabLogo from '../../../static/images/successStories/saab.png';
import esaLogo from '../../../static/images/successStories/esa.png';
import slgLogo from '../../../static/images/successStories/slg.png';

const stories = [{
  title: 'Leverage internal AI/ML capacities by up to 250%',
  description: `One of the large german healthcare provider has increased their POC output by over 250% 
  as ML development is a joint and collaboration task. The development process is now not limited to internal 
  Data Science capabilities but distributed across multiple personas`,
  image: internalAIML,
},
{
  title: 'Building a fully reproducible and re-usable ML knowledgebase',
  description: `0-lab, a sub division of the European Space Agency (ESA), is using AI/ML Reasearch to drive innovation
  using Earth Observation (EO) data. MLREEF provides a fully reproucible environment that hosts an immutable, re-usable and interoperable ML & EO knowledgebase`,
  image: knowledgebase,
},
{
  title: 'Secure and govern your sensible data',
  description: `The sweadish defense company SaaB AB is using MLReef as their on-premise, high secure Machine Learning development platform
   - as open source & self-hosted version`,
  image: onPremise,
},
{
  title: 'Enable expert feedback and direct contribution within the ML life cycle',
  description: `Whenever deep Domain Knowledge is required, collaboration becomes key. At Schwable Ley Greiner, the data science process had subject-matter
  experts being directly involved in the ML life cycle, decreasing substantially.`,
  image: graph,
},
];

const SuccessStoriesAndStatistics = () => {
  const sectionRef = useRef(null);
  const [currentStory, setCurrentStory] = useState(null);
  const [sectionDetailsWidth, setSectionDetailsWidth] = useState(500);
  const detailsImageWidth = sectionDetailsWidth * 0.5;
  const cardsSeparation = sectionDetailsWidth * 0.05;
  const widthForCards = (sectionDetailsWidth / 4) - (sectionDetailsWidth * 0.06);
  const firstCardMarginLeft = (sectionDetailsWidth * 0.06) / 2;

  useEffect(() => {
    if (window) {
      setSectionDetailsWidth(window.innerWidth * 0.7);
    }
  }, []);

  const handleCardClick = (cardIndex) => {
    if (sectionDetailsWidth < 500) {
      if (cardIndex === 3) {
        setCurrentStory(0);
        return;
      }
      setCurrentStory(cardIndex + 1);
      return;
    }
    setCurrentStory(cardIndex === currentStory ? null : cardIndex);
  }

  const firstCard = <div
    onClick={() => handleCardClick(0)}
    className="success-stories-and-statistics-content-success-story-card"
    style={{
      width: sectionDetailsWidth > 500 ? widthForCards : "60vw",
      marginLeft: sectionDetailsWidth > 500 ? firstCardMarginLeft : 0,
      border: `2px solid var(--${currentStory === 0 ? 'info' : 'lessWhite'})`,
    }}
  >
    <p className="title">+250%</p>
    <p className="desc">
      more medical
      {' '}
      <span style={{ color: 'var(--info)', fontsize: 'medium' }}>ML POCs</span>
      {' '}
      completed
    </p>
    <img width={50} src={caduceus} alt="" />
  </div>

  const secondCard = <div
    onClick={() => handleCardClick(1)}
    className="success-stories-and-statistics-content-success-story-card"
    style={{
      width: sectionDetailsWidth > 500 ? widthForCards : "60vw",
      marginLeft: sectionDetailsWidth > 500 ? firstCardMarginLeft + widthForCards + cardsSeparation : 0,
      border: `2px solid var(--${currentStory === 1 ? 'info' : 'lessWhite'})`,
    }}
  >
    <p className="title">100%</p>
    <p className="desc">
      <span style={{ color: 'var(--info)', fontsize: 'medium' }}>
        reproducible & interoperable
      </span>
      {' '}
      ML research
    </p>
    <img width={50} src={esaLogo} alt="" />
  </div>

  const thirdCard = <div
    onClick={() => handleCardClick(2)}
    className="success-stories-and-statistics-content-success-story-card"
    style={{
      width: sectionDetailsWidth > 500 ? widthForCards : "60vw",
      marginLeft: sectionDetailsWidth > 500 ? firstCardMarginLeft + (widthForCards * 2) + (cardsSeparation * 2) : 0,
      border: `2px solid var(--${currentStory === 2 ? 'info' : 'lessWhite'})`,
    }}
  >
    <p className="title">100%</p>
    <p className="desc">
      <span style={{ color: 'var(--info)', fontsize: 'medium' }}>secure environment</span> for ML development
    </p>
    <img width={50} src={saabLogo} alt="" />
  </div>;

  const forthCard = <div
    onClick={() => handleCardClick(3)}
    className="success-stories-and-statistics-content-success-story-card"
    style={{
      width: sectionDetailsWidth > 500 ? widthForCards : "60vw",
      marginLeft: sectionDetailsWidth > 500 ? firstCardMarginLeft + (widthForCards * 3) + (cardsSeparation * 3) : 0,
      border: `2px solid var(--${currentStory === 3 ? 'info' : 'lessWhite'})`,
    }}
  >
    <p className="title">-75%</p>
    <p className="desc">
      less ML
      {' '}
      <span style={{ color: 'var(--info)', fontsize: 'medium' }}>
        project failure
      </span>
    </p>
    <img width={50} src={slgLogo} alt="" />
  </div>

  return (
    <div ref={sectionRef} className="success-stories-and-statistics">
      <div className="success-stories-and-statistics-content">
        {sectionDetailsWidth > 500 ?
          <>
            {firstCard}
            {secondCard}
            {thirdCard}
            {forthCard}
            {currentStory !== null && (
              <div className="success-stories-and-statistics-content-story-details">
                <p className="description">
                  {stories[currentStory].description}
                </p>
                <p className="title">
                  {stories[currentStory].title}
                </p>
                <img width={detailsImageWidth} src={stories[currentStory].image} alt="" />
              </div>
            )}
          </>
          : (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {[
                firstCard,
                secondCard,
                thirdCard,
                forthCard
              ].map((card, ind) => {
                if (currentStory === null && ind === 0) {
                  return card;
                }
                return ind === currentStory ? card : null;
              })}
            </div>
          )}
      </div>
    </div>
  );
};

export default SuccessStoriesAndStatistics;
