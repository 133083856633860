import React from 'react';
import './Aquarium.scss';

const Aquarium = props => {
  const { children, className } = props;

  return (
    <section className={`aquarium ${className}`}>
  {/*   
  Remove fishes as requested in https://gitlab.com/mlreef/www-mlreef-com/-/issues/15
  <div className="aquarium-fishes">
        <div className="fish-container first">
          <div className="fish swimming" />
        </div>
        <div className="fish-container second">
          <div className="fish swimming" />
        </div>
        <div className="fish-container third">
          <div className="fish swimming" />
        </div>
        <div className="fish-container four">
          <div className="fish swimming" />
        </div>
      </div> */}
      <div className="aquarium-content">
        {children}
      </div>
    </section>
  );
};

Aquarium.defaultProps = {
  className: '',
};

export default Aquarium;
