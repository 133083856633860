import React from 'react';
import './DevelopModels.scss';
import Checkbox from '../Checkbox';
import experimentsImg from '../../../static/images/experiments/image.png';

const DevelopModels = () => (
  <div className="develop-models">
    <h2 className="title"><span style={{ color: '#735da8' }}>Develop</span> reproducible models</h2>
    <div className="develop-models-content">
      <div className="develop-models-content-left">
        <p className="indication">EXPERIMENTS</p>
        <h3 className="title">
        <span style={{ color: '#735da8' }}>Run</span> experiments iteratively and with full reproductibility
        </h3>
        <p className="description">
          Training models has never been as easy and flexible at the same time.
          Do not lose any history with full version control on data, code, parameters,
          pipeline run and base environment.
        </p>
        <div className="checkmarks">
          <Checkbox label="Use any ML framework" />
          <Checkbox label="Track model performance and metrics" />
          <Checkbox label="Store & version model artifact and output" />
        </div>
      </div>
      <div className="develop-models-content-right">
        <img src={experimentsImg} alt="" width="100%" height="auto" />
      </div>
    </div>
  </div>
);

export default DevelopModels;
